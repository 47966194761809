#google-map {
  position: relative;
  display: flex;
  justify-content: center;
}

#map {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#map-type {
  position: absolute;
  z-index: 1;
  bottom: 68.7vh;
  left: 0vw
}

#selector {
  border-radius: 0px;
  font-family: sans-serif;
  width: 300px;
}

#map-wrapper {
  flex: 1;
  padding: 20px;
  /* Add some padding for better spacing */
  display: flex;
  flex-direction: column;
  align-items: center;
}

#location,
#terrain,
#legend {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  fill: #666666;
  stroke: #666666;
  background: none rgb(255, 255, 255);
  padding: 5px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

#terrain {
  top: 10%;
  right: 1%;
}

#location {
  bottom: 4vh;
  right: 2.5vw;
}

#legend {
  bottom: 1vh;
  right: 2.5vw;
}

#legend-window {
  z-index: 100;
  padding-left: 10px;
  position: absolute;
  width: 20%;
  bottom: 14vh;
  right: 0vw;
  background: rgb(255, 252, 246);
  border: 1px solid #62BEC9;
  border-radius: 12px;
  font-weight: bold;
}

.legend-row {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.legend-row img {
  padding-right: 20px;
}

.legend-row p {
  padding-bottom: 5px;
}

#terrain:hover {
  fill: #333333;
}

#location:hover {
  fill: #333333;
  stroke: #333333;
}

#infoWindow {
  position: relative;
  top: -18px
}

#map a {
  padding: 0;
  font-size: 12px;
  color: #62BEC9;
}

.gm-style-mtc {
  display: none;
  /* Adjust height as needed */
}

@media only screen and (max-width: 2560px) {
  #location {
    bottom: 11%;
  }

  #legend {
    bottom: 4%;
  }

  #legend,
  #location {
    right: 5%;
  }

}

@media only screen and (max-width: 1280px) {
  #map-wrapper {
    align-items: center;
  }

  #selector {
    border-radius: 0px;
    font-family: sans-serif;
    width: 300px;
  }


  #terrain {
    right: 1%;
  }

  #legend {
    bottom: 5%;
  }

  #location {
    bottom: 13%;
  }

  .gm-style-iw-d {
    top: -3.7vh;
  }
}

@media only screen and (max-width: 1024px) {

  #legend {
    bottom: 4%;
  }

  #location {
    bottom: 10%;
  }
}

@media only screen and (max-width: 912px) {

  #location,
  #terrain {
    right: 1.5%;
  }

  #legend {
    bottom: 8%;
    right: 7%;
  }

  #legend-window {
    bottom: 7%;
    right: 2%;
    width: 30%;
    height: 70%;
  }
}

@media only screen and (max-width: 820px) {
  #location {
    bottom: 22%;
  }
}

@media only screen and (max-width: 540px) {

  #legend,
  #terrain,
  #location {
    right: 3%
  }

  #terrain {
    top: 12%;
  }

  #location {
    bottom: 25%;
  }

  #infoWindow {
    position: relative;
    top: -18px
  }

  #legend {
    bottom: 35%;
  }

  #legend-window {
    bottom: 5%;
    width: 80%;
    height: 85%;
  }
}

@media only screen and (max-width: 280px) {

  #location,
  #legend,
  #terrain {
    right: 4%;
  }
}