@import "~react-image-gallery/styles/css/image-gallery.css";

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: #3498db;
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    font-size: 32px;
    text-align: center;
    cursor: pointer;
}

.confirm-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: #3498db;
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 32px;
    text-align: center;
    cursor: pointer;
}

#input-upload-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 20px;
    align-self: center;
}

.confirm {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: #3498db;
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 32px;
    text-align: center;
}

#upload-wrapper {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

.upload-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #75a2a8;
    padding: 20px;
    border-radius: 12px;
    filter: drop-shadow(0 0 1rem rgb(178, 178, 178));
}

#upload-wrapper:hover .btn {
    background-color: #CDEAEE;
    color: var(--text-grey);
}

.confirm-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #75a2a8;
    padding: 20px;
    border-radius: 12px;
    filter: drop-shadow(0 0 1rem rgb(178, 178, 178));
}

#input-wrapper {
    opacity: 0;
    position: absolute;
    height: 100%;
    border-radius: 12px;
    cursor: pointer;
}

#upload-wrapper:hover {
    /* background-color: rgb(213, 213, 213); */
    cursor: pointer;
}

input#file-upload-button {
    cursor: pointer;
}

#info-card {
    display: flex;
    flex-direction: column;
    text-align: start;
    align-items: center;
    margin-top: 20px;
    font-size: large;
    margin-bottom: 20px;
}

.playground-text {
    background-color: #CDEAEE;
    padding: 12px;
    border-radius: 12px;
}

.btn-label {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
}

body {
    /* background-image: url("../../resources/kidsground site proportion_-1.png"); */
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.1);
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    padding: 0;
}

.image-gallery-play-button {
    display: none;
}

button {
    transition: background 1s;
}

#edit-playground {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #62BEC9;
    fill: #62BEC9;
}

#edit-playground:hover {
    color: #478c94;
    fill: #478c94;
}

#edit-playground .icon {
    margin-right: 5px;
}

#edit-playground p {
    margin-right: 5px;
}

#playground-name {
    width: 50%
}

.fence-icon {
    height: 1%;
    width: 1%;
}

#commentSectionWrapper {
    display: flex;
    justify-content: center;
}

#commentSection {
    flex-direction: column;
    border: 1px solid #4a8f9b;
    width: 50vw;
    border-radius: 12px;
    margin-bottom: 40px;
}

#commentField {
    border: 1.5px solid #CDEAEE;
    border-radius: 12px;
    margin-bottom: 5px;
    width: 80%;
    margin-right: 20px;
}

.commentContent {
    border: 1.5px solid #CDEAEE;
    border-radius: 12px;
    margin-bottom: 5px;
    padding: 20px;
    padding-left: 5px;
    width: 80%;
}

.toy-card {
    display: flex;
    margin-bottom: -20px;
}

.fence-card {
    display: flex;
    margin-top: -15px;
}

.floor-card {
    display: flex;
}

#commentField,
#commentFieldLabel,
#commentSection h1,
.comment {
    margin-left: 12px;
}

#commentField:focus {
    border-width: 3px;
}

#commentButton {
    color: var(--text-grey);
    background-color: #CDEAEE;
    border: 1px solid #75a2a8;
    cursor: pointer;
    border-radius: 15px;
    font-size: 15px;
}

#commentButton:disabled {
    cursor: not-allowed;
    background-color: #cdeaeeb6;
}

.icon {
    margin-right: 1vw;
}

.transportIcon {
    margin-left: 1vw;
}

.name-rating {
    display: flex;
    justify-content: space-between;
}

.rating {
    margin-right: 30px;
}

@media only screen and (max-width: 912px) {
    #commentSection {
        width: 100vw;
    }

    #commentField {
        width: 50%;
    }
}