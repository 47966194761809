:root {
    --main-color: #CDEAEE;
    --color-dark: #1D2231;
    --text-grey: #506463;
    --text-on-dark-background: #FCFCFC;
}

.register-container {
    overflow-x: hidden;
    margin-left: -1vw;
    width: 102vw;
    height: 84.3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../background.jpg");
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.form-box {
    position: relative;
    top: -20vh;
    border-radius: 20px;
    width: 90%;
    max-width: 450px;
    background: transparent;
    padding: 50px 60px 70px;
    text-align: center;
}

.form-box h1 {
    font-size: 30px;
    margin-bottom: 60px;
    background-color: #CDEAEE;
    color: var(--text-grey);
    border-radius: 15px;
    padding: 1%;
    position: relative;
}

.form-box h1::after {
    content: '';
    width: 30px;
    height: 4px;
    border-radius: 3px;
    background: #fff;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
}

.input-field {
    background: #eaeaea;
    margin: 15px 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    max-height: 65px;
    transition: max-height 0.5s;
    overflow: hidden;
}

input {
    width: 100%;
    background: transparent;
    border: 0;
    outline: 0;
    padding: 18px 15px;
}

.input-field i {
    margin-left: 15px;
    color: #999;
}

.btn-field {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.btn-field button {
    margin-top: 12vh;
    flex-basis: 48%;
    background-color: var(--text-grey);
    color: #CDEAEE;
    border: 1px solid #75a2a8;
    filter: drop-shadow(0 0 1rem rgb(178, 178, 178));
    height: 50px;
    width: 100%;
    border-radius: 15px;
    outline: 0;
    cursor: pointer;
    transition: background 1s;
    font-size: 16px;
}

button:hover {
    background-color: #CDEAEE;
    color: var(--text-grey);
}

.input-group {
    height: 240px;
}

.btn-field button.disable {
    background: #eaeaea;
    color: #555;
}

.error {
    border-radius: 15px;
    background-color: var(--text-grey);
    color: #CDEAEE;
}

.password-check {
    width: 90%;
    padding-left: 0.75%;
    padding-top: 1vh;
    padding-bottom: 1vh;
    margin: 0.1% 5.5% 0.1% 5.5%;
    text-align: justify;
}

#registration-success {
    display: none;
    color: #CDEAEE;
    background-color: #1D2231;
}

#wrong-credentials-msg {
    background-color: #1D2231;
    padding: 12px;
    border-radius: 12px;
    color: #CDEAEE;
}

#username-exists-error {
    display: none;
    color: #CDEAEE;
    /* color:#62BEC9; */
    margin-top: 2rem;
}

#logout-btn {
    border: #CDEAEE 2px solid;
}

#checkIcon {
    margin-top: 2px;
}

#forgot-pass {
    font-size: 15px;
    color: #62BEC9;
}

@media only screen and (max-width: 1368px) {
    .register-container {
        background-size: contain;
    }
}

@media only screen and (max-width: 960px) {
    .register-container {
        overflow-y: hidden;
    }

}

@media only screen and (max-width: 1024px) {
    .form-box {
        top: -5vh;
    }

}

@media only screen and (max-width: 560px) {
    .register-container {
        width: 100%;
        height: 75%;
    }

}

@media only screen and (max-width: 480px) {

    .password-check {
        font-size: 14px;
    }

    .btn-field button {
        margin-top: 10vh;
        font-size: 14px;
    }
}

@media only screen and (max-width: 380px) {

    .register-container {
        height: 80vh;
    }

    .btn-field button {
        margin-top: 0vh;
        font-size: 12px;
    }

    .form-box {
        top: 0vh;
    }

    .input-field {
        max-height: 45px;
    }

}

/*Galaxy fold...*/
@media only screen and (max-width: 280px) {

    .btn-field {
        font-size: 20px;
    }

    .number-of-playgrounds {
        font-size: 20px;
        font-weight: bold;
    }

    .password-check {
        font-size: 12px;
    }

}