.login-wrapper,
.logout-wrapper,
.home-wrapper,
.map-wrapper,
.profile-wrapper,
.about-us-wrapper {
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
    padding: .5rem 1rem;
}

nav {
    background: #FCFCFC;
    position: fixed;
    display: inline-flex;
    justify-content: right;
    z-index: 100;
    top: 0;
    width: 100vw;
    height: 10vh;
    font-size: 20px
}

.site-logo {
    margin-right: 3vw;
    margin-left: 7vw;
}

.site-logo img {
    height: 15vh;
}

a {
    border: none;
    font-size: 20px;
    text-decoration: none;
    color: var(--text-grey);
}

a:hover {
    color: #62BEC9;
    cursor: pointer;
}

@media only screen and (max-width: 720px) {
    a {
        font-size: 12px;
        padding: 0
    }

    .home-wrapper {
        padding-top: 11.3px;
    }

    .profile-wrapper {
        padding-top: 12px;
    }
}

/*Galaxy fold...*/
@media only screen and (max-width: 280px) {
    a {
        font-size: 8px;
    }

    .home-wrapper {
        padding-top: 22px;
    }

    .login-wrapper div,
    .logout-wrapper div .home-wrapper div,
    .profile-wrapper div,
    .about-us-wrapper div,
    .map-wrapper div {
        font-size: 10.5px;
        padding: 0.1rem 0.4rem;
    }
}