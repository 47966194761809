@font-face {
    font-family: 'Plovdiv Sans';
    src: local('Plovdiv Sans'), url('../fonts/PlovdivTypeface-Fonts/PlovdivTypeface-Fonts/OTF/PlovdivSans.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

* {
  font-family: 'Plovdiv Sans';
}

:root {
    --main-color: #CDEAEE;
    --color-dark: #1D2231;
    --text-grey: #506463;
    --text-on-dark-background: #FCFCFC;
    font-family: 'Plovdiv Sans';
    color: var(--text-grey);
}

html {
  overflow-x: hidden;
}

.page {
  z-index: 2;
  position: relative;
  top: 15vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

#add-playground-btn:disabled {
  cursor: not-allowed;
  background-color: #cdeaeeb6;
}

.background {
  overflow-x: hidden;
  margin-left: -1vw;
  width: 102vw;
  background-image: url("../playgroundFormBackground.png");
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: contain;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

#warning-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

#warning {
  border: 2px solid #FECD1B;
  padding: 10px;
  border-radius: 12px;
  display: inline-flex;
  background-color: rgb(255, 255, 240);
}

#warning-text {
  text-align: center;
  font-size: 24px;
}

#warning-icon {
  fill: "#FECD1B";
  margin-top: 17px;
  margin-right: 20px
}

@media only screen and (max-width: 540px) {
    #warning-text {
      font-size: 14px;
    }

    #warning-icon {
      height: 35px;
    }
}